export const schedulesByCompany = `query SchedulesByCompany(
  $companyId: String
  $scheduleinfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
) {
  schedulesByCompany(
    companyId: $companyId
    scheduleinfo: $scheduleinfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      scheduleinfo
      companyId
      providerId
      locationId
      servicetypeId
      active
      internal
      slotStartStep
      startDate
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays        
    }
    nextToken
  }
}
`;
export const schedulesByCompanyForCount = `query SchedulesByCompany(
  $companyId: String
  $scheduleinfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
) {
  schedulesByCompany(
    companyId: $companyId
    scheduleinfo: $scheduleinfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      scheduleinfo
      providerId
      active        
    }
    nextToken
  }
}
`;
export const bookedByCompany = `query SchedulesByCompany(
  $companyId: String
  $scheduleinfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
) {
  schedulesByCompany(
    companyId: $companyId
    scheduleinfo: $scheduleinfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      scheduleinfo
      startDate
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
    }
    nextToken
  }
}
`;

export const timeblockByCompany = `query SchedulesByCompany(
  $companyId: String
  $scheduleinfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
) {
  schedulesByCompany(
    companyId: $companyId
    scheduleinfo: $scheduleinfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      scheduleinfo
      startDate
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
    }
    nextToken
  }
}
`;

export const schedulesByLocation = `query SchedulesByLocation(
  $locationId: String
  $scheduleinfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
) {
  schedulesByLocation(
    locationId: $locationId
    scheduleinfo: $scheduleinfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      scheduleinfo
      companyId
      providerId
      locationId
      servicetypeId
      active
      internal
      slotStartStep
      instructions
      postBookingInstructions
    }
    nextToken
  }
}
`;

export const servicesSchedulesByLocation = `query SchedulesByLocation(
  $locationId: String
  $scheduleinfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
) {
  schedulesByLocation(
    locationId: $locationId
    scheduleinfo: $scheduleinfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      scheduleinfo
    }
    nextToken
  }
}
`;

export const listAllScheduleItemsByLocation = `query SchedulesByLocation(
  $locationId: String
  $scheduleinfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
) {
  schedulesByLocation(
    locationId: $locationId
    scheduleinfo: $scheduleinfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      scheduleinfo
      companyId
      providerId
      locationId
      servicetypeId
      active
      internal
      slotStartStep
      instructions
      postBookingInstructions
      startDate
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
    }
    nextToken
  }
}
`;

export const timeblocksByLocation = `query SchedulesByLocation(
  $locationId: String
  $scheduleinfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
) {
  schedulesByLocation(
    locationId: $locationId
    scheduleinfo: $scheduleinfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      scheduleinfo
      startDate
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
    }
    nextToken
  }
}
`;

export const listSchedules = `query ListScheduleTimeblocks(
  $id: ID
  $scheduleinfo: ModelStringKeyConditionInput
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listScheduleTimeblocks(
    id: $id
    scheduleinfo: $scheduleinfo
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      scheduleinfo
      companyId
      providerId
      locationId
      servicetypeId
      active
      internal
      slotStartStep
      instructions
      postBookingInstructions
    }
    nextToken
  }
}
`;

export const listAllScheduleItems = `query ListScheduleTimeblocks(
  $id: ID
  $scheduleinfo: ModelStringKeyConditionInput
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listScheduleTimeblocks(
    id: $id
    scheduleinfo: $scheduleinfo
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      scheduleinfo
      companyId
      providerId
      locationId
      servicetypeId
      active
      internal
      slotStartStep
      instructions
      postBookingInstructions
      startDate
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
    }
    nextToken
  }
}
`;

export const listTimeblocks = `query ListScheduleTimeblocks(
  $id: ID
  $scheduleinfo: ModelStringKeyConditionInput
  $filter: ModelScheduleTimeblockFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listScheduleTimeblocks(
    id: $id
    scheduleinfo: $scheduleinfo
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      scheduleinfo
      startDate
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
      active
      locationId
      sdtutc
      tz
    }
    nextToken
  }
}
`;

export const providerServiceTypeQuery = `query GetProvider($id: ID!) {
  getProvider(id: $id) {
    servicetypes {
      items { servicetype {
        id
        name}
      }
      nextToken
    }
  }
}
`;

export const providerDataForScheduleQuery = `query GetProvider($id: ID!) {
  getProvider(id: $id) {
    servicetypes {
      items { servicetype {
        id
        name}
      }
      nextToken
    }
    locations {
      items {
        id
        name
        maxtraveltype
        traveldistance
        traveldistanceunit
      }
      nextToken
    }
  }
}
`;
