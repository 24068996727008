const now = new Date();
//trigger build
export const events = [
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2019, 11, 4, 0, 0, 0),
    end: new Date(2019, 11, 4, 0, 0, 0),
  },
  {
    id: 1,
    title: "Long Event",
    start: new Date(2015, 3, 7),
    end: new Date(2015, 3, 10),
  },

  {
    id: 2,
    title: "DTS STARTS",
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: "DTS ENDS",
    start: new Date(2016, 10, 6, 0, 0, 0),
    end: new Date(2016, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: "Some Event",
    start: new Date(2015, 3, 9, 0, 0, 0),
    end: new Date(2015, 3, 10, 0, 0, 0),
  },
  {
    id: 5,
    title: "Conference",
    start: new Date(2015, 3, 11),
    end: new Date(2015, 3, 13),
    desc: "Big conference for important people",
  },
  {
    id: 6,
    title: "Meeting",
    start: new Date(2015, 3, 12, 10, 30, 0, 0),
    end: new Date(2015, 3, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
  },
  {
    id: 7,
    title: "Lunch",
    start: new Date(2015, 3, 12, 12, 0, 0, 0),
    end: new Date(2015, 3, 12, 13, 0, 0, 0),
    desc: "Power lunch",
  },
  {
    id: 8,
    title: "Meeting",
    start: new Date(2015, 3, 12, 14, 0, 0, 0),
    end: new Date(2015, 3, 12, 15, 0, 0, 0),
  },
  {
    id: 9,
    title: "Happy Hour",
    start: new Date(2015, 3, 12, 17, 0, 0, 0),
    end: new Date(2015, 3, 12, 17, 30, 0, 0),
    desc: "Most important meal of the day",
  },
  {
    id: 10,
    title: "Dinner",
    start: new Date(2015, 3, 12, 20, 0, 0, 0),
    end: new Date(2015, 3, 12, 21, 0, 0, 0),
  },
  {
    id: 11,
    title: "Birthday Party",
    start: new Date(2015, 3, 13, 7, 0, 0),
    end: new Date(2015, 3, 13, 10, 30, 0),
  },
  {
    id: 12,
    title: "Late Night Event",
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 18, 2, 0, 0),
  },
  {
    id: 12.5,
    title: "Late Same Night Event",
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 17, 23, 30, 0),
  },
  {
    id: 13,
    title: "Multi-day Event",
    start: new Date(2015, 3, 20, 19, 30, 0),
    end: new Date(2015, 3, 22, 2, 0, 0),
  },
  {
    id: 14,
    title: "Today",
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
  {
    id: 15,
    title: "Point in Time Event",
    start: now,
    end: now,
  },
];

export const CustomerColumns = [
  {
    title: "Name",
    name: "user.name",
    align: "left",
    className: "",
  },
  {
    title: "Email Address",
    name: "user.emailaddress",
    align: "left",
    className: "",
  },
  {
    title: "Account Balance",
    name: "accountbalance",
    align: "center",
    className: "",
  },
  {
    title: "Currency",
    name: "currency",
    align: "center",
    className: "",
  },
];

export const CustomerLocationColumns = [
  {
    title: "Location Name",
    name: "locationname",
    align: "left",
    className: "",
  },
  {
    title: "Address",
    name: "addressoneline",
    align: "left",
    className: "",
  },
  {
    title: "Phone",
    name: "phone",
    align: "left",
    className: "",
  },
];

export const ReviewColumns = [
  {
    title: "Rating",
    name: "ratingstars",
    align: "left",
    className: "",
  },
  {
    title: "Provider Review",
    name: "ratingtext",
    align: "left",
    className: "",
  },
  {
    title: "Date Created",
    name: "createdAt",
    align: "left",
    className: "",
  },
];

export const BookingListColumns = [
  {
    title: "Description",
    name: "desc",
    align: "left",
    className: "",
  },
  {
    title: "Client Name",
    name: "clientName",
    align: "left",
    className: "",
  },
  {
    title: "Provider Name",
    name: "providerName",
    align: "left",
    className: "",
  },
  {
    title: "Service Name",
    name: "serviceName",
    align: "left",
    className: "",
  },
  {
    title: "Start Date",
    name: "startDate",
    align: "left",
    className: "",
  },
  {
    title: "Status",
    name: "status",
    align: "left",
    className: "",
  },
];

export const ScheduleColumns = [
  {
    title: "Location Name",
    name: "locationName",
    align: "left",
    className: "",
  },
  {
    title: "Service type",
    name: "serviceName",
    align: "left",
    className: "",
  },
  {
    title: "Time slot interval",
    name: "slotStartStep",
    align: "left",
    className: "",
  },
];

export const TimeBlockColumns = [
  {
    title: "Start Date",
    name: "startDate",
    align: "left",
    className: "",
  },
  {
    title: "End Date",
    name: "endDate",
    align: "left",
    className: "",
  },
  {
    title: "Availability",
    name: "type",
    align: "left",
    className: "",
  },
  {
    title: "Repeat",
    name: "repeat",
    align: "left",
    className: "",
  },
  {
    title: "Time Range",
    name: "timeRange",
    align: "left",
    className: "",
  },
];

export const orderColumns = [
  {
    title: "Company",
    name: "companyName",
    align: "left",
    className: "",
  },
  {
    title: "Client",
    name: "clientName",
    align: "left",
    className: "",
  },
  {
    title: "Provider",
    name: "providerName",
    align: "left",
    className: "",
  },
  {
    title: "Status",
    name: "status",
    align: "left",
    className: "",
  },
  {
    title: "Order Amount",
    name: "amount",
    align: "left",
    className: "",
  },
  {
    title: "Order No.",
    name: "id",
    align: "left",
    className: "",
  },
];

export const dayAbbreviations = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const VARIABLE_TAX_RATE = "variable";

export const MAX_FIRST_NAME_LENGTH = 15;
