import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import {
  Box,
  Button,
  Divider,
  List,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { Logger } from "@aws-amplify/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import { Link, useHistory } from "react-router-dom";
import Cache from "@aws-amplify/cache";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getUser } from "../graphql/queries";
import MenuIcon from "@mui/icons-material/Menu";
import { USERROLE } from "../user/UserCommon";
import { GUEST_ONLY } from "../user/checkoutOption";

export default function MainNavBar(props) {
  const [userName, setUserName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bookingFlowSettings, setBookingFlowSettings] = useState(null);
  let history = useHistory();

  const logger = new Logger("MainNavBar");
  const phoneWidthPadding =
    window.innerHeight <= "600" || window.innerWidth <= "600"
      ? "16px" //"40px 24px 50px 40px"
      : "40px 24px 50px 100px";

  useEffect(() => {
    async function getUserName() {
      if (props && props.user) {
        const data = await API.graphql(
          graphqlOperation(getUser, {
            id: props.user.username,
          })
        );

        if (data && data.data && data.data.getUser) {
          if (data.data.getUser.firstname && data.data.getUser.lastname)
            setUserName(
              `${data.data.getUser.firstname} ${data.data.getUser.lastname}`
            );
          else setUserName(data.data.getUser.emailaddress);
        }
      }
    }
    if (props) {
      if (props.guestUser) setUserName("Guest");
      else getUserName();
    }
  }, []);

  useEffect(() => {
    console.log("Props PublicSiteSettings MainNavbar.js", props);
    if (!bookingFlowSettings && props?.publicsitesettings?.StyleCustomization) {
      console.log("Are you here?");
      setBookingFlowSettings({
        ...props.publicsitesettings.StyleCustomization
          .bookingFlowCustomizations,
        showHeadings: true,
      });
    }
    console.log(
      "Props BookingFlow Settings MainNavbar.js",
      bookingFlowSettings
    );
  }, [props, bookingFlowSettings]);
  function getCompanyName() {
    let companyname;
    if (props && props.company) {
      companyname = props.company.name;
    } else {
      logger.debug(
        "WARNING: No company name passed in props. Using MarketBox."
      );
      companyname = "MarketBox";
    }
    return companyname;
  }

  function getCompanyLogoUrl() {
    let logoUrl;
    if (props && props.company) {
      logoUrl = props.company.logoUrl;
    } else {
      logger.debug("WARNING: No company logo passed in props.");
      logoUrl = "";
    }
    return logoUrl;
  }

  function isAdmin() {
    const user = Cache.getItem("user");
    if (user && user.role)
      return (
        USERROLE.MARKETBOX_ADMIN === user.role ||
        USERROLE.COMPANY_ADMIN === user.role ||
        USERROLE.COMPANY_ADMIN_PROVIDER === user.role
      );
    else return false;
  }
  const txtColor = localStorage.getItem("textColor")
    ? localStorage.getItem("textColor")
    : "000000";

  const logoClickHandler = () => {
    if (props.company.homepage) {
      window.open(props.company.homepage, "_blank").focus();
    } else {
      window.open(process.env.REACT_APP_AUTH_SITE_URL, "_blank").focus();
    }
  };
  // display company logo
  function DisplayCompanyLogo() {
    const logo = getCompanyLogoUrl();
    if (props && props.company && !props.company.displayOnlyLogo) {
      // display Avatar logo since we are also displaying company name
      return (
        <div>
          {logo ? (
            <img
              height={avatarSize}
              src={getCompanyLogoUrl()}
              alt={getCompanyName()}
              onClick={logoClickHandler}
            />
          ) : (
            ""
          )}
        </div>
      );
    } else {
      // display logo as rectangle
      return (
        <>
          {logo ? (
            <div style={{ display: "flex" }}>
              <img
                height={avatarSize}
                alt={getCompanyName()}
                src={getCompanyLogoUrl()}
                onClick={logoClickHandler}
              />
            </div>
          ) : (
            /*<Typography style={{ color: txtColor }} variant="h1">
                {getCompanyName()}
              </Typography>*/

            ""
          )}
        </>
      );
    }
  }

  // display company name if configured
  function DisplayCompanyName() {
    if (props && props.company && !props.company.displayOnlyLogo) {
      return (
        <Typography
          style={{ fontSize: "1.25rem", color: txtColor, marginLeft: "12px" }}
          variant={!phoneWidthSmaller ? "h2" : "h4"}
          noWrap
        >
          {getCompanyName()}
        </Typography>
      );
    } else {
      return "";
    }
  }

  async function handleClick() {
    setAnchorEl(null);
    if (props && props.guestUser) {
      //Login
      history.push("/login", { fromPath: props.currentPath });
    } else {
      //Logout
      try {
        // await Auth.signOut();
        Cache.removeItem("user");
        props.signOutOp();
      } catch (err) {
        logger.error("Error signing out user", err);
      }
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "logout-menu" : undefined;

  function dropDownItemsHandler(str) {
    if (str.toLowerCase() === "dashboard") {
      window.open("https://pro.gomarketbox.com", "_blank");
    }

    if (str.toLowerCase() === "help") {
      window.open("https://www.gomarketbox.com/help", "_blank");
    }

    if (str.toLowerCase() === "contact") {
      window.open("https://www.gomarketbox.com/contact-us", "_blank");
    }
  }

  //const textColor = props.publicsitesettings.textColor;
  const heading1Text = localStorage.getItem("heading1Text");
  const heading2Text = localStorage.getItem("heading2Text");
  const deviceWidth = useMediaQuery("(min-width:768px)") ? "h1" : "h2";
  const avatarSize = useMediaQuery("(min-width:600px)") ? 80 : 60;
  const phoneWidthSmaller = useMediaQuery("(min-width:600px)");

  function DisplayLoginInfo() {
    if (
      GUEST_ONLY === bookingFlowSettings?.checkoutOption &&
      !props.guestCheckout
    )
      return <div style={{ marginLeft: "auto" }}></div>;
    if (!props.user) return <div style={{ marginLeft: "auto" }}></div>;
    return (
      <div style={{ marginLeft: "auto" }}>
        {props && props.guestCheckout ? (
          <Button variant="contained" color="secondary">
            Guest
          </Button>
        ) : props.guestUser ? (
          <Button
            id="login-button"
            variant="contained"
            color="secondary"
            onClick={handleClick}
          >
            Log In
          </Button>
        ) : (
          <>
            <Button
              id="login-button"
              sx={{
                backgroundColor: "primary.main",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                  border: "1px white solid",
                  boxShadow: "none",
                },
              }}
              aria-describedby={id}
              variant="contained"
              color="secondary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              <Typography
                variant="body1"
                sx={{ textTransform: "capitalize", fontWeight: 700 }}
              >
                Hello,{" "}
                <span
                  style={{
                    fontWeight: 700,
                    fontFamily: "Roboto",
                    textTransform: "capitalize",
                  }}
                >
                  {userName}
                </span>
              </Typography>
            </Button>
            <Popover
              PaperProps={{
                style: { width: "257px", padding: "1rem" },
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List>
                <ListItemText
                  style={{
                    marginBottom: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => dropDownItemsHandler("dashboard")}
                >
                  Dashboard
                </ListItemText>
                {isAdmin() && (
                  <ListItemText
                    style={{ marginBottom: "1rem", cursor: "pointer" }}
                    onClick={() => dropDownItemsHandler("help")}
                  >
                    Help center
                  </ListItemText>
                )}

                <Divider style={{ marginBottom: "1rem", width: "100%" }} />

                <ListItemText
                  id="logout-button"
                  component={Link}
                  style={{ marginBottom: "1rem", cursor: "pointer" }}
                  onClick={handleClick}
                >
                  Logout
                </ListItemText>

                <ListItemText
                  style={{
                    marginBottom: "1rem",
                    color: "rgba(0,0,0,0.5)",
                  }}
                >
                  <span style={{ fontSize: "12px", fontFamily: "Roboto" }}>
                    MarketBox v2.0.0.
                  </span>
                </ListItemText>
              </List>
            </Popover>
          </>
        )}
      </div>
    );
  }

  function smallerDisplayLoginInfo() {
    if (
      GUEST_ONLY === bookingFlowSettings?.checkoutOption &&
      !props.guestCheckout
    )
      return <div style={{ marginLeft: "auto" }}></div>;
    if (!props.user) return <div style={{ marginLeft: "auto" }}></div>;

    return (
      <div style={{ marginLeft: "auto" }}>
        {props && props.guestCheckout ? (
          <>
            {" "}
            <IconButton
              size="medium"
              style={{
                background: "rgba(0,0,0,0.5)",
                width: "40px",
                height: "40px",
                borderRadius: "8px",
              }}
              variant="contained"
              color="secondary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MenuIcon style={{ padding: 0, color: "#fff" }} />
            </IconButton>
            <Popover
              PaperProps={{
                style: { width: "250px", padding: "0.75rem" },
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List>
                <ListItemText
                  style={{
                    marginBottom: "0.5rem",
                    cursor: "pointer",
                  }}
                >
                  Guest checkout
                </ListItemText>
              </List>
            </Popover>
          </>
        ) : props.guestUser ? (
          <>
            <IconButton
              size="medium"
              style={{
                background: "rgba(0,0,0,0.5)",
                width: "40px",
                height: "40px",
                borderRadius: "8px",
              }}
              variant="contained"
              color="secondary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MenuIcon style={{ padding: 0, color: "#fff" }} />
            </IconButton>
            <Popover
              PaperProps={{
                style: { width: "250px", padding: "0.75rem" },
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List>
                <ListItemText
                  style={{
                    marginBottom: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                >
                  Login
                </ListItemText>
              </List>
            </Popover>
          </>
        ) : (
          <>
            <IconButton
              size="medium"
              style={{ width: "40px", height: "40px", borderRadius: "8px" }}
              aria-describedby={id}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MenuIcon style={{ padding: 0, color: txtColor }} />
            </IconButton>
            <Popover
              PaperProps={{
                style: { width: "250px", padding: "0.75rem" },
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List>
                <ListItemText>
                  <Typography
                    style={{
                      marginBottom: "0.5rem",
                      fontWeight: 700,
                    }}
                    variant="body1"
                  >
                    Hello,{" "}
                    <span style={{ fontWeight: 700, fontFamily: "Roboto" }}>
                      {userName}
                    </span>
                  </Typography>
                </ListItemText>
                <Divider style={{ marginBottom: "1rem", width: "100%" }} />
                <ListItemText
                  style={{
                    marginBottom: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => dropDownItemsHandler("dashboard")}
                >
                  Dashboard
                </ListItemText>
                {isAdmin() && (
                  <ListItemText
                    style={{ marginBottom: "1rem", cursor: "pointer" }}
                    onClick={() => dropDownItemsHandler("help")}
                  >
                    Help center
                  </ListItemText>
                )}
                <Divider style={{ marginBottom: "1rem", width: "100%" }} />

                <ListItemText
                  component={Link}
                  style={{ marginBottom: "1rem", cursor: "pointer" }}
                  onClick={handleClick}
                >
                  Logout
                </ListItemText>

                <ListItemText
                  style={{
                    marginBottom: "1rem",
                    color: "rgba(0,0,0,0.5)",
                  }}
                >
                  <span style={{ fontSize: "12px", fontFamily: "Roboto" }}>
                    MarketBox v2.0.0.
                  </span>
                </ListItemText>
              </List>
            </Popover>
          </>
        )}
      </div>
    );
  }

  if (
    !props.banner ||
    !(bookingFlowSettings && bookingFlowSettings?.showHeader)
  ) {
    return (
      <AppBar
        style={{
          height: "88px",
          padding: !phoneWidthSmaller ? "12px 10px" : "0px 20px",
          display: "flex",
          justifyContent: !phoneWidthSmaller ? "center" : "space-evenly",
        }}
        elevation={0}
      >
        <Toolbar>
          {DisplayCompanyLogo()}
          {DisplayCompanyName()}
          {!phoneWidthSmaller ? smallerDisplayLoginInfo() : DisplayLoginInfo()}
        </Toolbar>
      </AppBar>
    );
  } else {
    return (
      <Box
        sx={{
          background: props?.publicsitesettings?.StyleCustomization
            ?.bookingFlowCustomizations
            ? props?.publicsitesettings?.StyleCustomization
                ?.bookingFlowCustomizations?.backgroundColor
            : "#ffffff",
          overflow: "auto",
          color: localStorage.getItem("textColor")
            ? localStorage.getItem("textColor")
            : props.textColor,
          minHeight: "350px",
          height: "370px",
          overflowY: "hidden",
          top: 0,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: phoneWidthPadding,

          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: bookingFlowSettings ? bookingFlowSettings.opacity : 0.2,
            //zindex: -1,
            backgroundImage: `url("${
              localStorage.getItem("backgroundImgUrl")
                ? localStorage.getItem("backgroundImgUrl")
                : props.publicsitesettings.backgroundImageUrl
            }")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: localStorage.getItem("textColor")
              ? localStorage.getItem("textColor")
              : props.textColor,
          },
        }}
      >
        {DisplayLoginInfo()}

        {bookingFlowSettings &&
          bookingFlowSettings.showLogo &&
          !bookingFlowSettings.circularLogo && (
            <img
              style={{ zIndex: "10", cursor: "pointer" }}
              height={avatarSize}
              alt={getCompanyName()}
              src={getCompanyLogoUrl()}
              onClick={logoClickHandler}
            />
          )}

        {bookingFlowSettings &&
          bookingFlowSettings.showLogo &&
          bookingFlowSettings.circularLogo && (
            <img
              style={{
                zIndex: "10",
                cursor: "pointer",
                borderRadius: "50%",
                objectFit: "cover",
                height: "80px",
              }}
              alt={getCompanyName()}
              src={getCompanyLogoUrl()}
              onClick={logoClickHandler}
            />
          )}

        {bookingFlowSettings &&
          bookingFlowSettings.showHeadings &&
          (bookingFlowSettings.hasOwnProperty("showHeadingOne")
            ? bookingFlowSettings.showHeadingOne
            : true) && (
            <Typography
              style={{
                marginTop: "1.5rem",
                marginBottom: "0.5rem",
                color: props.publicsitesettings.textColor
                  ? props.publicsitesettings.textColor
                  : "#d4d4d4",
                cursor: "pointer",
                zIndex: "10",
                fontFamily: `${
                  bookingFlowSettings
                    ? bookingFlowSettings.fontTypeForAllHeading
                    : "Raleway"
                }, Roboto`,
                fontSize: bookingFlowSettings
                  ? !phoneWidthSmaller
                    ? "2.15rem"
                    : bookingFlowSettings.fontSizeHeader1
                  : phoneWidthSmaller
                  ? "2.15rem"
                  : "3.75rem",
              }}
              variant={deviceWidth}
              onClick={logoClickHandler}
            >
              {/* {props.company.name} */}
              {bookingFlowSettings
                ? bookingFlowSettings.mainHeading
                : "Please set Heading 1 in company public settings"}
            </Typography>
          )}

        {bookingFlowSettings &&
          bookingFlowSettings.showHeadings &&
          (bookingFlowSettings.hasOwnProperty("showHeadingTwo")
            ? bookingFlowSettings.showHeadingTwo
            : true) && (
            <Typography
              style={{
                color: props.publicsitesettings.textColor
                  ? props.publicsitesettings.textColor
                  : "#d4d4d4",
                marginBottom: "0.5rem",

                fontFamily: `${
                  bookingFlowSettings
                    ? bookingFlowSettings.fontTypeForAllHeading
                    : "Raleway"
                }, Roboto`,
                fontSize: bookingFlowSettings
                  ? !phoneWidthSmaller
                    ? "1rem"
                    : bookingFlowSettings.fontSizeHeader2
                  : !phoneWidthSmaller
                  ? "1rem"
                  : "1.5rem",
                zIndex: 11,
              }}
            >
              {heading1Text
                ? heading1Text
                : "Please set Heading 2 in company public settings"}
            </Typography>
          )}
        {bookingFlowSettings &&
          bookingFlowSettings.showHeadings &&
          (bookingFlowSettings.hasOwnProperty("showHeadingThree")
            ? bookingFlowSettings.showHeadingThree
            : true) && (
            <Typography
              sx={{
                color: props.publicsitesettings.textColor
                  ? props.publicsitesettings.textColor
                  : "#d4d4d4",
                fontFamily: `${
                  bookingFlowSettings
                    ? bookingFlowSettings.fontTypeForAllHeading
                    : "Raleway"
                }, Roboto`,
                fontSize: bookingFlowSettings
                  ? !phoneWidthSmaller
                    ? "0.9rem"
                    : bookingFlowSettings.fontSizeHeader3
                  : !phoneWidthSmaller
                  ? "0.9rem"
                  : "1rem",
                zIndex: 12,
              }}
            >
              {heading2Text
                ? heading2Text
                : "Please set Heading 3 in company public settings"}
            </Typography>
          )}
      </Box>
    );
  }
}
