import React, { useState, useEffect } from "react";
import Rating from "@mui/material/Rating";
// prettier-ignore
import { Logger } from "@aws-amplify/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
// prettier-ignore
import { Paper, Dialog, DialogTitle, DialogActions, DialogContent, CircularProgress, InputAdornment, Grid, Avatar, TextField, Button, Container, Typography, useMediaQuery, Chip } from "@mui/material";
import { Box, Snackbar } from "@mui/material";
import { useStyles } from "../../styles/ProviderAddEditStyles";
import MainNavBar from "../MainNavbar";
import moment from "moment";
import { logSystemError } from "../../modules/SystemErrorService";
import { chargeCreditCard } from "../../modules/BillingCommon";
import { sendSms } from "../../modules/MessagingService";
import { getStripeAmount } from "../../modules/BookingService";
import { getCurrencySymbolByCompany } from "../../utils";
import { getCompanyAdmins } from "../../user/UserCommon";
import { execReadByPK } from "../../modules/DBService";
import { providerRatingsByProvider } from "../../queries/CustomQueries";

const logger = new Logger("TipPage");
const limit = process.env.REACT_APP_LISTLIMIT; // global setting for list limits

export const TipPage = (props) => {
  const classes = useStyles();
  const paperclasses = useStyles();
  const [provider, setProvider] = useState();
  const [providerPic, setProviderPic] = useState("");
  const [apptDate, setApptDate] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [location, setLocation] = useState("");
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [hover, setHover] = React.useState(-1);
  const [tipMsg, setTipMsg] = useState("Add a tip for ");
  const [tipAmt, setTipAmt] = useState("");
  const [booking, setBooking] = useState();
  const [snackMsg, setSnackMsg] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [tipPercent, setTipPercent] = useState(0);
  const [customTipModel, setCustomTipModal] = useState(false);
  const [customTipAmt, setCustomTipAmt] = useState("");
  const [customTip, setCustomTip] = useState(false);
  const [user, setUser] = useState(props.authuser);
  const limit = process.env.REACT_APP_LISTLIMIT; // global setting for list limits
  const [done, setDone] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenId, setTokenId] = useState(null);
  const [companyAdmins, setCompanyAdmins] = useState([]);
  const [tipText, setTipText] = useState(null);
  const [bookingAddress, setBookingAddress] = useState("");
  const smallerWidthThanSixHundred = useMediaQuery("(min-width:600px)");

  const labels = {
    1: "Unsatisfactory",
    2: "OK",
    3: "Good",
    4: "Very good",
    5: "Excellent",
  };

  useEffect(() => {
    async function load() {
      await getOrderInfo();
    }
    load();
  }, []);

  async function getOrderInfo() {
    let bookingId, token;

    let search = window.location.search;
    let params = new URLSearchParams(search);

    if (params) {
      if (params.has("b") && params.get("b") !== "") {
        bookingId = params.get("b");
      } else {
        // invalid URL
        window.location.href = "/";
        return;
      }
      if (params.has("t") && params.get("t") !== "") {
        token = params.get("t");
        setTokenId(token);
      } else {
        // invalid URL
        window.location.href = "/";
        return;
      }
    }

    try {
      // validate token
      let input = {
        id: token,
      };

      let response = await API.graphql(
        graphqlOperation(queries.getPublicAccessToken, input)
      );

      let result = response.data.getPublicAccessToken;

      const expiryDate = new Date(result.expiryDate);
      const now = new Date();
      const role = result.role;

      // test if token is valid (has correct role and has not expired)
      if (expiryDate.getTime() > now.getTime() && role === "TIPPAGE") {
        // get booking info
        input = {
          id: bookingId,
        };
        response = await API.graphql(
          graphqlOperation(queries.getBooking, input)
        );
        result = response.data.getBooking;

        logger.debug("result = " + JSON.stringify(result));
        logger.debug("provider = " + JSON.stringify(result.provider));
        setBooking(result);
        logger.debug("booking = " + result.order.subtotal);
        const provider = result.provider;
        setProvider(provider);
        setButtonText("Rate " + provider.firstname);
        setServiceName(result.servicetype.name);
        setApptDate(moment(result.startdate).format("MMM DD, YYYY"));
        if (providerPic.length === 0) {
          const picresult = await Storage.get(provider.pictures3key);
          setProviderPic(picresult);
        }
        setLocation(result.location ? result.location : "No Location");
      } else {
        logger.error("Token invalid or expired");
        window.location.href = "/";
        return;
      }
    } catch (err) {
      logger.error("*** an error occurred. The error was: " + err.message);
      window.location.href = "/";
      return;
    }
  }

  useEffect(() => {
    console.log("Booking State Tip Page");
    console.log(booking);
  }, [booking]);

  function setTipInfo(percent) {
    setTipPercent(percent);
    setTipMsg(percent + "% tip added for ");
    setTipAmt(booking && (booking.servicetype.price * percent) / 100);
  }

  function handleClickOpt1() {
    const percent = 15;
    setTipInfo(percent);
    setTipText("15");
  }

  function handleClickOpt2() {
    const percent = 20;
    setTipInfo(percent);
    setTipText("20");
  }

  function handleClickOpt3() {
    const percent = 25;
    setTipInfo(percent);
    setTipText("25");
  }

  function handleClickNoTip() {
    setTipMsg("No tip chosen for ");
    setTipPercent(0);
    setTipAmt(0);
    setTipText("none");
  }

  function handleCustomTip() {
    setCustomTipModal(true);
    setTipText("custom");
  }

  function saveCustomTip() {
    setTipAmt(customTipAmt);
    setCustomTip(true);
    //setTipMsg("Custom tip added for ");
    //setCustomTipModal(false);
  }

  async function saveRating() {
    let input = {
      providerRatingsProviderId: provider.id,
      providerId: provider.id,
      ratingstars: rating,
      ratingtext: review,
      active: true,
      ratinguserId: booking?.client?.user?.id
        ? booking?.client?.user?.id
        : user.id,
      companyId: booking?.company?.id,
    };
    logger.debug("input:" + input);
    const newReview = await API.graphql(
      graphqlOperation(mutations.createProviderRatings, { input })
    );
    logger.debug("New review created = ");
    logger.debug(newReview);

    //If rating is low, send low rating notification
    if (rating < 3) {
      //Poor or Very Bad rating.
      await sendLowRatingEmailNotification(input);
    }

    // now recalcuate average stars
    const result = await execReadByPK({
      opname: "providerRatingsByProvider",
      op: providerRatingsByProvider,
      id: { providerId: provider.id },
      filter: {
        active: { eq: true },
      },
      sortDirection: "DESC",
    });

    let totalstars = 0,
      avgstars = 0;

    for (let i = 0; i < result.items.length; i++) {
      totalstars += result.items[i].ratingstars;
    }

    avgstars = totalstars / result.items.length;

    const numberofratings = !!provider.numberofratings
      ? provider.numberofratings + 1
      : 1;
    // save avgstars
    input = {
      id: provider.id,
      ratingstarsavg: avgstars,
      numberofratings,
      firstname: provider.firstname,
      lastname: provider.lastname,
      createdAt: provider.createdAt,
    };

    const newProvider = await API.graphql(
      graphqlOperation(mutations.updateProvider, { input })
    );

    // Adding rating to the booking itself
    const ratingInput = {
      id: booking.id,
      bookingRatingId: newReview.data.createProviderRatings.id,
    };
    const updateRatingResult = await API.graphql(
      graphqlOperation(mutations.updateBooking, { input: ratingInput })
    );
  }

  async function createAddCharge(billingDesc) {
    const dateNow = new Date();
    const options = { dateStyle: "long" };
    const dateNowString =
      dateNow.toLocaleDateString("en-US", options) + // use en-US for now
      " " +
      dateNow.toLocaleTimeString();

    let input = {
      amount: tipAmt,
      desc:
        "Tip for " +
        provider.firstname +
        " " +
        provider.lastname +
        ". Tip processed on " +
        dateNowString,
      note: billingDesc,
      bookingId: booking.id,
      orderId: booking.order.id,
      additionalChargeOrderId: booking.order.id,
      additionalChargeUserId: user.id,
      additionalChargeCompanyId: booking.company.id,
      companyId: booking.company.id,
      additionalChargeClientId: booking.client.id,
      clientId: booking.client.id,
      additionalChargeCreatorId: booking.client.user.id,
      creatorId: booking.client.user.id,
    };

    let result = await API.graphql(
      graphqlOperation(mutations.createAdditionalCharge, { input })
    );
  }

  async function updateBookingAddCharge() {
    const totalAddChg = (booking.totalAddChg || 0) + Number.parseFloat(tipAmt);
    const input = {
      id: booking.id,
      startdate: booking.startdate,
      timeblockid: booking.timeblockid,
      totalAddChg,
    };
    const result = await API.graphql(
      graphqlOperation(mutations.updateBooking, { input })
    );
    logger.debug("totalAddChgResult = " + JSON.stringify(result));
  }

  async function createBillingTransaction(billingDesc) {
    let billingTransactionId = 0;

    let input = {
      billingTransactionClientId: booking.client.id,
      clientId: booking.client.id,
      amount: tipAmt,
      billingTransactionCreatorId: booking.client.user.id,
      creatorId: booking.client.user.id,
      billingTransactionCompanyId: booking.company.id,
      companyId: booking.company.id,
      desc: billingDesc,
      credit: false,
    };
    if (booking.order) {
      input = {
        ...input,
        billingTransactionOrderId: booking.order.id,
        orderId: booking.order.id,
      };
    }
    let result = await API.graphql(
      graphqlOperation(mutations.createBillingTransaction, { input })
    );

    if (result && result.data) {
      // create stripe payment for tip
      billingTransactionId = result.data.createBillingTransaction.id;
    }

    return billingTransactionId;
  }

  async function createStripePayment(billingDesc, billingTransactionId) {
    const emailaddress =
      booking.client.user && booking.client.user.emailaddress
        ? booking.client.user.emailaddress
        : "stripe@gomarketbox.com";

    let currency = booking.order.currency;
    if (!currency) currency = booking.company.currency;
    //const currency = booking.company.currency.toString();

    // charge via Stripe
    const stripe_result = await chargeCreditCard(
      booking.client.stripeCustomerId,
      getStripeAmount(tipAmt), // tipAmt * 100, // stripe integer value includes cents
      currency,
      emailaddress,
      false,
      0,
      booking.company.id,
      booking.company.stripeAccount,
      booking.company.stripeConnectEnabled
    );
    logger.debug("stripe_result = ");
    logger.debug(stripe_result);

    // save the data to the ClientCharge table
    const dateNow = new Date();
    const options = { dateStyle: "long" };
    const dateNowString =
      dateNow.toLocaleDateString("en-US", options) + // use en-US for now
      " " +
      dateNow.toLocaleTimeString();
    let input = {
      clientId: booking.client.id,
      clientChargeClientId: booking.client.id,
      companyId: booking.company.id,
      description: "Client generated tip amount created on " + dateNowString,
      status: "created",
      stripechargeid: stripe_result.charge.id,
      stripepaymentmethod: stripe_result.charge.payment_method,
      stripe_payment_method_details:
        stripe_result.charge.stripe_payment_method_details,
      balance_transaction: stripe_result.charge.balance_transaction,
      stripe_refund_charge: stripe_result.charge.id,
      stripe_status: stripe_result.charge.status,
      clientChargeBillingtransactionId: billingTransactionId,
      billingtransactionId: billingTransactionId,
      amount: tipAmt,
      amount_refunded: 0,
      currency: currency,
    };
    if (booking.order) {
      input = {
        ...input,
        clientChargeOrderId: booking.order.id,
        orderId: booking.order.id,
      };
    }
    let result = await API.graphql(
      graphqlOperation(mutations.createClientCharge, { input })
    );
  }

  async function sendLowRatingEmailNotification(input) {
    //Getting company Admins
    const companyAdminsList = await getCompanyAdmins(booking.company.id);
    let companyAdminsEmailList = [];
    for (let companyAdmin of companyAdminsList) {
      companyAdminsEmailList.push(companyAdmin.emailaddress);
    }
    const companyAdmins = companyAdminsEmailList;

    //Creating necessary values
    const clientName =
      booking.client.user.firstname +
      " " +
      booking.client.user.lastname.charAt(0) +
      ".";
    const numStars = input.ratingstars;
    const providerName = provider.firstname + " " + provider.lastname.charAt(0);
    const serviceTypeName = booking.servicetype.name;

    //Creating Data object
    const dataObj = {
      client_name: clientName,
      num_stars: numStars,
      provider_name: providerName,
      servicetype_name: serviceTypeName,
      isRegisteredUser: true,
    };

    //Send email
    const result = await API.post(
      "sendtwilioemail",
      "/sendtwilioemailtemplate",
      {
        body: {
          templateName: "low-rating-notification.html",
          subject: `${numStars} Star Rating from ${clientName} for ${providerName}`,
          body: null,
          toAddresses: [provider.emailaddress],
          ccAddresses: [],
          bccAddresses: companyAdmins,
          replyTo: booking.company.replyemailaddress,
          companyName: booking.company.name,
          companyId: booking.company.id,
          company: booking.company,
          dataObj: dataObj,
          companyLogoUrl: booking.company.logoUrl,
          companyColor: booking.company.primaryColor,
          authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL,
        },
      }
    );
    console.log("low rating notification email sent!");
  }

  async function sendSMSPaymentNotification() {
    const from = process.env.REACT_APP_TWILIO_SMS_SEND_NUMBER;
    const to = booking.client.user.mobilephone;
    //const email = booking.client.user.emailaddress;
    const companyName = booking.company.name;
    const companyEmail = booking.company.emailaddress;
    const companyCode = booking.company.countrycode3166alpha2
      ? "en-" + booking.company.countrycode3166alpha2.toUpperCase()
      : "en-CA";
    logger.debug("companycode = " + companyCode);
    const servicedesc = booking.desc;
    let currency = booking.order.currency;
    const encodedURIEmail = encodeURIComponent(companyEmail);
    if (!currency) currency = booking.company.currency;
    const total = tipAmt.toLocaleString(companyCode, {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
    });

    const msg =
      "Message from " +
      companyName +
      ". A tip amount of " +
      total +
      " has been added to your service '" +
      servicedesc +
      "'. Thank you for your generosity. Please contact " +
      encodedURIEmail +
      " if this tip was made in error.";
    logger.debug("msg to = " + to);
    logger.debug("msg = " + msg);
    const smsresult = await sendSms(to, msg, getCountryCodeForSms());
    logger.debug("smsresult = " + JSON.stringify(smsresult));
  }

  async function sendProviderTipEmailNotification() {
    const companyAdminsList = await getCompanyAdmins(booking.company.id);
    let companyAdminsEmailList = [];
    for (let companyAdmin of companyAdminsList) {
      companyAdminsEmailList.push(companyAdmin.emailaddress);
    }
    const companyAdmins = companyAdminsEmailList;

    //Generating values to replace tokens
    const companyCode = booking.company.countrycode3166alpha2
      ? "en-" + booking.company.countrycode3166alpha2.toUpperCase()
      : "en-CA";
    let currency = booking.order.currency;
    if (!currency) currency = booking.company.currency;
    const total = tipAmt.toLocaleString(companyCode, {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
    });
    const clientName =
      booking.client.user.firstname +
      " " +
      booking.client.user.lastname.charAt(0);

    //Data object with [token] : value pairs
    const dataObj = {
      provider_firstname: provider.firstname,
      dollars: total,
      client_name: clientName,
      company_name: booking.company.name,
      isRegisteredUser: true,
    };

    console.log("asdf provider:", provider);

    //Send email
    const result = await API.post(
      "sendtwilioemail",
      "/sendtwilioemailtemplate",
      {
        body: {
          templateName: "provider-tip-notification.html",
          subject: `You’ve just received a tip from ${clientName}`,
          body: null,
          toAddresses: [provider.emailaddress],
          ccAddresses: [],
          bccAddresses: companyAdmins,
          replyTo: booking.company.replyemailaddress,
          companyName: booking.company.name,
          companyId: booking.company.id,
          company: booking.company,
          dataObj: dataObj,
          companyLogoUrl: booking.company.logoUrl,
          companyColor: booking.company.primaryColor,
          authSiteUrl: process.env.REACT_APP_AUTH_SITE_URL,
        },
      }
    );
    console.log("asdf result");
  }

  async function sendProviderTipSMSNotification() {
    console.log("asdf booking info", booking);
    const from = process.env.REACT_APP_TWILIO_SMS_SEND_NUMBER;
    const to = provider.phone;
    const companyName = booking.company.name;
    const companyCode = booking.company.countrycode3166alpha2
      ? "en-" + booking.company.countrycode3166alpha2.toUpperCase()
      : "en-CA";
    let currency = booking.order.currency;
    if (!currency) currency = booking.company.currency;
    const total = tipAmt.toLocaleString(companyCode, {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
    });
    const clientName =
      booking.client.firstname + " " + booking.client.lastname.charAt(0);

    const msg =
      "Message from " +
      companyName +
      ". You have received a tip of " +
      total +
      " from " +
      clientName +
      " for your service '" +
      serviceName +
      "'. Keep up the great work!";
    logger.debug("msg to = " + to);
    logger.debug("msg = " + msg);
    const smsresult = await sendSms(to, msg, getCountryCodeForSms());
    logger.debug("smsresult = " + JSON.stringify(smsresult));
  }

  function getCountryCodeForSms() {
    if (booking.order.bookingAddress) {
      let addr = JSON.parse(booking.order.bookingAddress);
      return addr.countryCode;
    } else {
      return booking.company.countrycode3166alpha2;
    }
  }

  const handleRatingTip = async (data) => {
    // WE DO THE FOLLOWING:
    // 1. Create rating entry
    // 2. Create Additional Charge entry
    // 3. Create Billing Transaction entry
    // 4. Create Stripe payment
    // 5. Create ClientCharge entry
    // 6. Send SMS to client to notify of additional charge
    // 7. Send Email to Provider and Admins of Tip

    // if done, return to public site
    /*if (done) {
      window.location.href = "/";
    }*/

    if (!loading && !done) {
      setLoading(true);
    }

    if (rating === 0) {
      setSnackMsg(
        "Please enter a rating (number of stars) for " + provider.firstname
      );
      setMsgOpen(true);
      setLoading(false);
      return;
    }

    try {
      // save rating and re-calc stars
      await saveRating();

      const billingDesc =
        "Tip specified by customer for " +
        provider.firstname +
        " " +
        provider.lastname +
        ". Service provided: " +
        serviceName +
        ". Location: " +
        location +
        " on " +
        apptDate;

      // create additional charge
      if (tipAmt > 0) {
        await createAddCharge(billingDesc);
        await updateBookingAddCharge();
        // create billing transaction
        const billingTransactionId = await createBillingTransaction(
          billingDesc
        );

        // create stripe payment
        await createStripePayment(billingDesc, billingTransactionId);

        // Send SMS to client to notify of additional charge
        await sendSMSPaymentNotification();

        //Send Email to provider that they received money
        await sendProviderTipEmailNotification();
        console.log("asdf sent correctly!");
        //Send SMS to provider that they received money
        // (Inactive, twilio bills too high. Uncomment to activate - please test)
        // await sendProviderTipSMSNotification();
      }

      let finalMsg;
      if (tipAmt > 0) {
        finalMsg =
          "The review and tip for " +
          provider.firstname +
          " have been processed. Thank you.";
      } else {
        finalMsg =
          "The review for " +
          provider.firstname +
          " has been processed. Thank you.";
      }

      // expire token
      const input = {
        id: tokenId,
        expiryDate: new Date(),
      };
      await API.graphql(
        graphqlOperation(mutations.updatePublicAccessToken, { input })
      );

      setSnackMsg(finalMsg);
      setMsgOpen(true);
      setDone(true);
      window.location.href = "/";
    } catch (err) {
      logger.error("*** an error occurred. The error was: " + err.message);
      logger.error(err);
      const errorresult = await logSystemError(
        booking.company.id,
        "ERROR",
        "Saving Rating and Tip on TipPage",
        0,
        err
      );
      logger.debug(errorresult);
      setSnackMsg(
        `Unable to save rating ` +
          `${tipAmt > 0 ? "and tip" : "information."}
          `
      );
      setMsgOpen(true);
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={msgOpen}
        autoHideDuration={3000}
        onClose={() => setMsgOpen(false)}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMsg}</span>}
      />
      <MainNavBar company={props.company} />
      {booking && (
        <Container maxWidth="lg" style={{ marginTop: "115px" }}>
          {
            //if provider exists
            provider && (
              /* Image, Name and Bio */
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                rowSpacing={2}
              ></Grid>
            )
          }

          {/* Left Right Layout */}
          <Grid container spacing={smallerWidthThanSixHundred ? 7 : 0}>
            {/* Left Side */}
            <Grid item sm={12} md={8}>
              <Grid
                container
                item
                md={12}
                justifyContent="flex-start"
                direction="column"
              >
                <Typography
                  style={{
                    marginTop: !smallerWidthThanSixHundred ? "1.5rem" : "60px",
                    marginBottom: "1rem",
                  }}
                  variant="h3"
                >
                  {`Leave a review for ${
                    booking.provider.firstname
                  } ${booking.provider.lastname.substring(0, 1)}. for ${
                    serviceName ? serviceName : ""
                  }`}
                </Typography>
                {/* <Typography variant="body1">
                  How satisfied were you with the service?
                </Typography> */}
              </Grid>

              {/* Stars */}
              <Grid
                container
                item
                md={12}
                justifyContent="flex-start"
                direction="row"
                alignItems={"center"}
              >
                <Rating
                  style={{ color: props.selectedColor }}
                  name="hover-side"
                  value={rating}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                  size="large"
                />
                <Box ml={1} sx={{ marginTop: "4px" }}>
                  {labels[hover !== -1 ? hover : rating]}
                </Box>
              </Grid>

              {/* Textbox */}
              <Grid
                container
                style={{ marginTop: "1rem" }}
                item
                md={12}
                justifyContent="flex-start"
                direction="row"
              >
                <TextField
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  id="outlined-multiline-static"
                  label="Review"
                  value={review}
                  onChange={(event) => setReview(event.target.value)}
                  InputProps={{ disableUnderline: true }}
                  multiline
                  rows="4"
                />
              </Grid>

              {localStorage.getItem("showTipOption") !== "false" && (
                /*Tip amount */
                <Grid
                  container
                  item
                  md={12}
                  justifyContent="flex-start"
                  direction="column"
                >
                  {provider && (
                    <Typography
                      style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      variant="body1"
                    >
                      Add a tip for{" "}
                      {`${provider.firstname} ${provider.lastname.substring(
                        0,
                        1
                      )}.`}
                    </Typography>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        gap: !smallerWidthThanSixHundred ? "0.4rem" : "0.75rem",
                        width: !smallerWidthThanSixHundred ? "100%" : "60%",
                      }}
                    >
                      <Chip
                        variant={
                          tipText && tipText === "15" ? "filled" : "outlined"
                        }
                        color="primary"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          /* flexGrow: 1, */
                          cursor: "pointer",
                          "&.MuiChip-filled": {
                            background: props.selectedColor,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "primary.main",
                          },
                          "&.MuiChip-outlined": {
                            borderColor: "#808080",
                            color: "#808080",
                          },
                          fontFamily: "Roboto",
                          fontSize: "1rem",
                          width: "23.5%",
                        }}
                        onClick={handleClickOpt1}
                        label={"15%"}
                      />

                      <Chip
                        variant={
                          tipText && tipText === "20" ? "filled" : "outlined"
                        }
                        color="primary"
                        sx={{
                          width: "23.5%",

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          /* flexGrow: 1, */
                          cursor: "pointer",
                          "&.MuiChip-filled": {
                            background: props.selectedColor,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "primary.main",
                          },
                          "&.MuiChip-outlined": {
                            borderColor: "#808080",
                            color: "#808080",
                          },
                          fontFamily: "Roboto",
                          fontSize: "1rem",
                        }}
                        onClick={handleClickOpt2}
                        label={"20%"}
                      />

                      <Chip
                        variant={
                          tipText && tipText === "25" ? "filled" : "outlined"
                        }
                        color="primary"
                        sx={{
                          width: "23.5%",

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          /* flexGrow: 1, */
                          cursor: "pointer",
                          "&.MuiChip-filled": {
                            background: props.selectedColor,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "primary.main",
                          },
                          "&.MuiChip-outlined": {
                            borderColor: "#808080",
                            color: "#808080",
                          },
                          fontFamily: "Roboto",
                          fontSize: "1rem",
                        }}
                        onClick={handleClickOpt3}
                        label={"25%"}
                      />
                      <Chip
                        variant={
                          tipText && tipText === "custom"
                            ? "filled"
                            : "outlined"
                        }
                        color="primary"
                        sx={{
                          width: "29.5%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          "&.MuiChip-filled": {
                            background: props.selectedColor,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "primary.main",
                          },
                          "&.MuiChip-outlined": {
                            borderColor: "#808080",
                            color: "#808080",
                          },

                          fontFamily: "Roboto",
                          fontSize: "1rem",
                        }}
                        onClick={handleCustomTip}
                        label={"Custom"}
                      />
                    </div>
                  </div>

                  {tipText && tipText === "custom" && (
                    <TextField
                      sx={{
                        marginTop: "1.5rem",
                        width: !smallerWidthThanSixHundred ? "100%" : "300px",
                        //maxWidth: "300px"
                      }}
                      variant="outlined"
                      size="small"
                      type="number"
                      id="amount"
                      label="Custom amount"
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: booking && (
                          <InputAdornment position="start">
                            <strong>
                              {getCurrencySymbolByCompany(booking.company)}
                            </strong>
                          </InputAdornment>
                        ),
                      }}
                      value={tipAmt}
                      onChange={(event) => {
                        setTipAmt(event.target.value);
                        //saveCustomTip();
                      }}
                    />
                  )}
                </Grid>
              )}

              <Grid
                container
                item
                md={12}
                justifyContent="flex-start"
                direction="column"
              >
                {provider && (
                  <Button
                    style={{
                      marginTop: "1.5rem",
                      width: !smallerWidthThanSixHundred ? "100%" : "300px",
                      //maxWidth: "300px"
                    }}
                    variant="contained"
                    onClick={handleRatingTip}
                    disabled={loading || !rating}
                  >
                    {/* `Rate ${" "}${
                      provider.firstname
                    } ${provider.lastname.substring(0, 1)}.` */}
                    {loading ? (
                      <CircularProgress size={12} sx={{ color: "#fff" }} />
                    ) : (
                      `Submit Review`
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};
