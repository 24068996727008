export const providerRatingsByProvider = /* GraphQL */ `
  query ProviderRatingsByProvider(
    $providerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderRatingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerRatingsByProvider(
      providerId: $providerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        providerId
        ratingstars
        ratingtext
        ratinguserId
        active
      }
      nextToken
    }
  }
`;
